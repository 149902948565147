// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LoggingLevel } from '@isophi/core-legacy';

import { commonEnvironment } from './environment.common';

export const environment = {
  envShortcut: 'D',
  production: false,
  logLevel: LoggingLevel.DEBUG,
  common: commonEnvironment,
  client_id: 'Ny1aiionLm0xuAblsYe9VVBzSB0NrDbnooF08Ter',
  grant_type: 'password',
  ga_measurement_id: '',
  i18n: true,
  replaysSampleRate: 0,
  tracesSampleRate: 0,

  teacherAPI: 'https://api.isophi-merged.doc.endevel.cz/teacher/api/v1',
  authAPI: 'https://api.isophi-auth.doc.endevel.cz/api/v1',
  licenseAPI: 'https://api.isophi-merged.doc.endevel.cz/license/api/v1',
  materialAPI: 'https://api.isophi-merged.doc.endevel.cz/material/api/v1',
  menuAPI: 'https://api.isophi-merged.doc.endevel.cz/menu/api/v1',
  materialServer: 'https://api.isophi-material.doc.endevel.cz',
  dadApplicationUrl: 'https://isophi-dad-app.doc.endevel.cz',
  pdfApiBase: 'https://api.isophi-pdf.doc.endevel.cz',
  managementApplicationUrl: 'https://isophi-management-app.doc.endevel.cz',
  sentryDSN: 'https://a2f7284960004bc89aef95c809a67024@o1002766.ingest.sentry.io/4505052513894400',
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
