import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Kindergarten, KindergartenContract, Teacher, User } from '@isophi/core-legacy';
import { ListResponseContract, TeacherContract } from '@isophi/teachers-shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KindergartenService {
  public loggedKindergarten: Kindergarten | null;

  private teacherSubject$ = new BehaviorSubject<void>(null);

  public constructor(private httpClient: HttpClient) {}

  /**
   * List of teachers (Users with role teacher)
   */
  public get teachers$(): Observable<User[]> {
    const url = `${environment.teacherAPI}/teachers/`;

    return this.teacherSubject$.pipe(
      distinctUntilChanged(),
      switchMap(() => {
        return this.httpClient.get<ListResponseContract<TeacherContract>>(url).pipe(
          map((response) =>
            response.results.map((teacherData) => {
              const user = User.deserialize(teacherData.user);
              Teacher.deserialize(teacherData, user);
              return user;
            })
          )
        );
      }),
      shareReplay(1)
    )
  }

  public reloadTeachersList(): void {
    this.teacherSubject$.next();
  }

  /**
   * Download logged kindergarten.
   *
   * Kindergarten is downloaded during login,
   * in application use directly loggedKindergarten field.
   */
  public downloadLoggedKindergarten(): Promise<void> {
    const url = `${environment.teacherAPI}/general/logged-kindergarten/`;

    return this.httpClient
      .get<KindergartenContract>(url)
      .toPromise()
      .then((response) => {
        this.loggedKindergarten = Kindergarten.deserialize(response);
        return null;
      });
  }
}
