/* eslint-disable @typescript-eslint/naming-convention */
import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@isophi/core-legacy';
import memo from 'memo-decorator';

import { environment } from '../../../environments/environment';
import { RouterLinkFactory } from '../../core/router/router-link.factory';

@Pipe({
  name: 'prepareLinks'
})
export class PrepareLinksPipe implements PipeTransform {
  targetTypesLinks = {};

  constructor(
    private links: RouterLinkFactory,
    private authService: AuthService,
  ) {
    this.targetTypesLinks = {
      "diagnostics_external": `${environment.dadApplicationUrl}/login/external?token=${this.authService.accessToken}&source=tapp`,
      "children_and_results": this.links.childList(),
      "dashboard": this.links.homepage(),
      "children_groups": this.links.groupReportProblematicCategories(),
      "children_group_list": this.links.groupList(),
      "children_archive": this.links.childArchive(),
      "materials": this.links.materialsEducational(),
      "materials-diagnostic": this.links.materialsDiagnostic(),
      "materials-educational": this.links.materialsEducational(),
      "materials_diagnostic": this.links.materialsDiagnostic(),
      "materials_educational": this.links.materialsEducational(),
      "manuals": this.links.manuals(),
      "game": this.links.games(),
      "smart": this.links.games(),
      "smart_promo": 'https://isophi.cz/smart',
      "auto_evaluation": 'https://sites.google.com/view/setpre-cz',
      "e_learning": [],
      "teacher_management": this.links.teacherList(),
      "isophi_management": environment.managementApplicationUrl
    }
  }

  @memo()
  transform(targetType: string): (string | number)[] {
    return this.targetTypesLinks[targetType];
  }

}
